import "./elements.css";
import React, { useEffect } from "react";
import { MdClose } from "react-icons/md";

import { handleHeaderByType } from "../../helpers/header-action";

const ModalVariable = (props) => {
	useEffect(() => {
		props.setNameVar("");
	}, []);

	return (
		<>
			<div className={`modalBlackout ${props.active ? "" : "modalHide"}`}></div>
			<div className={`modalDiv ${props.active ? "" : "modalHide"}`}>
				<div className="modalHeader">
					{handleHeaderByType("newVar")}
					<div
						className="modalHeader-close"
						onClick={props.handleModalVariable}
					>
						<MdClose size={22} />
					</div>
				</div>
				<div className="modalContent">
					<div className="node-question">
						<div>
							<label>Nome da variável</label>
							<input
								type="text"
								className="inputText"
								value={props.nameVar}
								onChange={(e) => props.setNameVar(e.target.value)}
							/>
						</div>
					</div>
				</div>
				<div className="modalBottom">
					<button
						onClick={props.handleModalVariable}
						className="btnModal btnCancel"
					>
						Cancelar
					</button>
					<button onClick={props.saveVar} className="btnModal btnSaveModal">
						Salvar
					</button>
				</div>
			</div>
		</>
	);
};

export default ModalVariable;
