import React from "react";

import CloneNode from "../Nodes/Elements/editNode";
import DeleteNode from "../Nodes/Elements/deleteNode";

const NodeHeader = ({
	data,
	text,
	icon,
	showOnly = [],
	start = false,
	rest,
}) => {
	function handleButtons() {
		const buttons = {
			clone: (
				<CloneNode
					node={data.currentNode}
					setNodes={data.setNodes}
					data={data}
					rest={rest}
				></CloneNode>
			),
			delete: (
				<DeleteNode
					deleteNode={data.deleteNodeById}
					nodeId={data.ref}
				></DeleteNode>
			),
		};
		if (showOnly.length) {
			const buttonToShow = showOnly.find((button) => buttons[button]);
			return buttons[buttonToShow];
		}
		return (
			<>
				<CloneNode
					node={data.currentNode}
					setNodes={data.setNodes}
					data={data}
					rest={rest}
				></CloneNode>

				<DeleteNode
					deleteNode={data.deleteNodeById}
					nodeId={data.ref}
				></DeleteNode>
			</>
		);
	}

	return (
		<div
			className="sendMessageTitle node-header"
			style={{
				fontSize: start && "16px",
			}}
		>
			<div>
				{icon}
				<span>{text}</span>
			</div>
			{data && <div className="node-header-buttons">{handleButtons()}</div>}
		</div>
	);
};

export { NodeHeader };
