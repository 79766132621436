import axios from "axios";

let urlServer;
if (process.env.NODE_ENV === "development") {
  urlServer = "http://localhost:4040";
  // urlServer = "https://bot.spread.chat";
} else {
  urlServer = "https://bot.spread.chat";
}

const serverApi = axios.create({
  baseURL: urlServer,
});

export { serverApi };
