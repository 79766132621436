import { useCallback } from "react";
import { useReactFlow } from "reactflow";

const ValidatorFn = () => {
	const { getEdges, getNodes } = useReactFlow();

	return useCallback(
		(connection) => {
			const edges = getEdges();
			const nodes = getNodes();

			const hasAlready = edges.filter(
				(edge) =>
					edge.source === connection.source &&
					edge.target &&
					nodes.find((nd) => nd.id === edge.target)
			);

			const node = nodes.find((n) => n.id === connection.source);

			if (["webhook", "conditional"].includes(node?.type)) {
				if (hasAlready.length === 2) return false;
				if (hasAlready.length + 1 > 2) {
					return false;
				}
				return true;
			}

			if (node?.type === "question") {
				if (node.data.questionButtons?.length) {
					if (hasAlready.length >= node.data.questionButtons?.length) {
						return false;
					} else {
						return true;
					}
				}

				if (node.data.questionList?.sections?.length) {
					let sectionsItems = 0;
					for (const a of node.data.questionList.sections) {
						for (const _ of a.items) {
							sectionsItems++;
						}
					}
					if (hasAlready.length >= sectionsItems) {
						return false;
					} else {
						return true;
					}
				}

				if (hasAlready.length) {
					return false;
				}

				return true;
			}

			if (node?.type === "jump") {
				if (node.data.jumps?.length) {
					if (hasAlready.length >= node.data.jumps?.length) {
						return false;
					}
				}

				return true;
			}

			return !hasAlready.length;
		},
		[getNodes, getEdges]
	);
};

export { ValidatorFn };
