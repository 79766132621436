import "./index.css";
import React, { memo } from "react";
import { FaPlus, FaSitemap } from "react-icons/fa";
import { Handle } from "reactflow";

import { ValidatorFn } from "../../../helpers/validationConnector";
import { NodeHeader } from "../../Elements/NodeHeader";

export default memo(({ data, ...rest }) => {
	return (
		<>
			<label className="nodeRef">Ref: {data.ref}</label>
			<Handle type="target" position="left" isConnectable={true} />

			<NodeHeader
				data={data}
				rest={rest}
				icon={<FaSitemap />}
				text="Keyword Jump"
			/>

			<div className="sendMessageContent">
				<div>
					Variavel: <b>@{data?.jumpVar}</b>
				</div>
			</div>

			<div className="questionButtons">
				{data.jumps &&
					data.jumps.map((x, i) => (
						<Item i={i} x={x} isLast={i === data.jumps.length - 1} />
					))}
			</div>
		</>
	);
});

function Item({ i, x, isLast }) {
	return (
		<div className={isLast ? "item-last" : ""}>
			<div className="conditionalContent">{x}</div>
			<Handle
				type="source"
				position="right"
				id={x}
				className="handle"
				isConnectable={true}
				isValidConnection={ValidatorFn()}
			>
				<FaPlus size={8} style={{ pointerEvents: "none" }} />
			</Handle>
		</div>
	);
}
