import React, { useContext, useEffect, useState } from "react";
import AddVariablesInText from "../AddVariablesInText";
import { handleContentToPut } from "../../../helpers/replace-variables-html";
import { AccountContext } from "../../../context/Account";

const hardCodedFields = [
	"name",
	"description",
	"source",
	"amount",
	"priority",
	"forecastPropability",
	"pipeline",
	"stage",
	"owner_id",
	"contacts",
];

const ignoreEmptyOption = ["priority", "owner_id"];

function CreateDeal({
	objectData,
	owners,
	pipelines,
	customDealFields,
	variables,
}) {
	const [stages, setStages] = useState([]);
	const { account } = useContext(AccountContext);

	useEffect(() => {
		const hasPipeline = objectData.pipeline || objectData.pipeline_id;
		const stageValue = objectData.stage || objectData.stage_id;
		if (hasPipeline) {
			setCurrentPipelineStage(hasPipeline, stageValue);
		}

		let inputs = [
			...document.querySelectorAll(".form_create_deal div.inputText"),
		];
		if (objectData) {
			Object.entries(objectData).forEach(([k, v]) => {
				const found = inputs.find((el) => el.id == k);
				if (found && v && found.nodeName === "DIV") {
					found.innerHTML = handleContentToPut(v, variables);
				}
			});
		}
		if (!Object.keys(objectData).length) {
			inputs.forEach((inpt) => {
				if (inpt.nodeName === "DIV") {
					inpt.innerHTML = "";
				}
			});
		}
	}, [objectData]);

	const setCurrentPipelineStage = (value, stage_value = null) => {
		const selected = pipelines.find((pipe) => pipe.id == value)?.stages || [];
		setStages(selected);
		let options = "<option value></option>";
		selected.forEach((stage) => {
			options += `<option value="${stage.id}">${stage.name}</option>`;
		});

		const stageEl = document.querySelector(
			account.elozVersion === "new" ? "#stage_id" : "#stage"
		);

		stageEl.innerHTML = options;

		if (stage_value) {
			stageEl.value = stage_value;
		}
	};

	const handleFieldType = (field) => {
		let name = field.alias;

		if (account.elozVersion === "old") {
			name = `{{DEAL-CUSTOM-FIELD=${field.id}}}=${field.alias}`;

			if (hardCodedFields.includes(field.alias)) {
				name = field.alias;
			}

			if (field.alias === "pipeline") {
				field.properties.list = pipelines.map((p) => ({
					value: p.id,
					label: p.name,
				}));
			}
			if (field.alias === "stage") {
				field.properties.list = stages.map((p) => ({
					value: p.id,
					label: p.name,
				}));
			}
		}

		if (field.alias === "owner_id") {
			field.properties.list = owners.map((owner) => ({
				value: owner.id,
				label: `${owner.name} (${owner.email})`,
			}));
		}
		if (field.alias === "priority") {
			const priorities = [
				{
					label: "Baixa",
					value: 1,
				},
				{
					label: "Media",
					value: 2,
				},
				{
					label: "Alta",
					value: 3,
				},
			];
			field.properties.list = priorities;
		}

		const v = objectData?.[field.alias] || objectData?.[name] || "";

		if (field.type === "user") {
			field.type = "select";
		}

		const types = {
			text() {
				return (
					<div id={name} className="inputText" contentEditable="true">
						{v}
					</div>
				);
			},
			number() {
				return (
					<div id={name} className="inputText" contentEditable="true">
						{v}
					</div>
				);
			},
			select() {
				return (
					<select
						name={name}
						id={name}
						onChange={(e) => {
							if (field.alias === "pipeline_id" || field.alias === "pipeline") {
								setCurrentPipelineStage(e.target.value);
							}
						}}
					>
						{ignoreEmptyOption.includes(field.alias) ? (
							""
						) : (
							<option value=""></option>
						)}
						{field.properties.list.map((propValue) => (
							<option
								selected={objectData?.[name] == propValue.value}
								value={propValue.value}
							>
								{propValue.label}
							</option>
						))}
					</select>
				);
			},
			multiselect() {
				return (
					<select name={name} id={name} multiple="multiple">
						<option value=""></option>
						{field.properties.list.map((propValue, i) => (
							<option
								value={propValue.value}
								selected={objectData?.[name]?.some(
									(b) => b === propValue.value
								)}
							>
								{propValue.label}
							</option>
						))}
					</select>
				);
			},
			date() {
				return (
					<input type="date" className="inputText" defaultValue={v} id={name} />
				);
			},
			datetime() {
				return (
					<input
						type="datetime-local"
						className="inputText"
						defaultValue={v}
						id={name}
					/>
				);
			},
			time() {
				return <input className="inputText" type="time" value={v} id={name} />;
			},
			textarea() {
				return (
					<div id={name} className="inputText" contentEditable="true">
						{v}
					</div>
				);
			},
		};

		return types[field.type]();
	};

	return (
		<div className="form_create_deal">
			<h3>Criar negócio:</h3>
			<div className="form-create">
				{customDealFields.map((field) => {
					const isRequired = field.isRequired ? "required" : "";
					return (
						<div className="input-group">
							<label htmlFor="" className={isRequired}>
								{field.label}
							</label>
							{handleFieldType(field)}
						</div>
					);
				})}
				<AddVariablesInText variables={variables}></AddVariablesInText>
			</div>
		</div>
	);
}

export { CreateDeal };
