const requiredFields = ["owner_id", "name", "priority", "pipeline", "stage"];

function validateDealData() {
	let inputs = [
		...document.querySelectorAll(".form_create_deal div.inputText"),
	];
	let inputsDates = [...document.querySelectorAll(".form_create_deal input")];
	let selects = [...document.querySelectorAll(".form_create_deal select")];
	let fields = {};

	inputs.forEach((input) => {
		fields[input.id] = input.textContent;
	});
	inputsDates.forEach((input) => {
		fields[input.id] = input.value;
	});

	selects.forEach((select) => {
		let value = select.value;
		if (select.getAttribute("multiple") !== null) {
			const options = select.options;
			value = [];
			for (var i = 0, l = options.length; i < l; i++) {
				if (options[i].selected) {
					value.push(options[i].value);
				}
			}
		}
		fields[select.id] = value;
	});

	// if (requiredFields.some((field) => !fields[field])) {
	// 	return false;
	// }

	return fields;
}

export { validateDealData };
