import React from "react";
import { BaseEdge, EdgeLabelRenderer, getSmoothStepPath } from "reactflow";

import { ReactComponent as Trash } from "../../icons/trash.svg";

import "./buttonedge.css";

export default function CustomEdge({
	id,
	sourceX,
	sourceY,
	targetX,
	targetY,
	sourcePosition,
	targetPosition,
	style = {},
	markerEnd,
	data,
}) {
	const [edgePath, labelX, labelY] = getSmoothStepPath({
		sourceX,
		sourceY,
		sourcePosition,
		targetX,
		targetY,
		targetPosition,
	});

	const onEdgeClick = (evt, id) => {
		evt.stopPropagation();

		data?.setEdges((edges) => edges.filter((ed) => ed.id !== id));
	};

	return (
		<>
			<BaseEdge
				path={edgePath}
				markerEnd={markerEnd}
				style={{
					strokeWidth: 2,
				}}
			/>
			<EdgeLabelRenderer>
				<div
					data-line-action={id}
					id={id}
					style={{
						position: "absolute",
						transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
						fontSize: 12,
						display: "none",
						pointerEvents: "all",
					}}
					className="nodrag nopan"
				>
					<button
						className="edgebutton"
						title="Remover conexão"
						onClick={(event) => onEdgeClick(event, id)}
					>
						<Trash />
					</button>
				</div>
			</EdgeLabelRenderer>
		</>
	);
}
