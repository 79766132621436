import React, { memo } from "react";
import { FaPen, FaPlus } from "react-icons/fa";
import { Handle } from "reactflow";

import "./index.css";
import { ValidatorFn } from "../../../helpers/validationConnector";
import { NodeHeader } from "../../Elements/NodeHeader";

export default memo(({ data, ...rest }) => {
	return (
		<>
			<label className="nodeRef">Ref: {data.ref}</label>
			<Handle type="target" position="left" isConnectable={true} />

			<NodeHeader
				data={data}
				rest={rest}
				icon={<FaPen />}
				text="Setar valor de variável"
			/>

			<div className="sendMessageContent">
				<div>
					@{data.varToSet} = <b>"{data.valueToset}"</b>
				</div>
			</div>

			<Handle
				type="source"
				position="right"
				className="handle"
				isConnectable={true}
				isValidConnection={ValidatorFn()}
			>
				<FaPlus size={8} style={{ pointerEvents: "none" }} />
			</Handle>
		</>
	);
});
