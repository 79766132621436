import React from "react";

import "./elements.css";

export default (props) => {
  return (
    <button className="btnSave" onClick={props.saveBot}>
      Salvar
    </button>
  );
};
