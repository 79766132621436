const defaultVariables = [
	"name",
	"email",
	"phone",
	"contacttoken",
	"typechat",
	"whatsappassigned",
	"day",
	"hour",
	"minute",
	"typeMinute",
	"channelType",
	"igResponseType",
];

export { defaultVariables };
