import "./index.css";
import React, { memo } from "react";
import { FaToggleOn } from "react-icons/fa";
import { Handle } from "reactflow";

import { NodeHeader } from "../../Elements/NodeHeader";

export default memo(({ data, ...rest }) => {
	return (
		<>
			<label className="nodeRef">Ref: {data.ref}</label>

			<Handle type="target" position="left" isConnectable={true} />

			<NodeHeader
				rest={rest}
				data={data}
				icon={<FaToggleOn />}
				text="Encerrar conversa"
			/>
		</>
	);
});
