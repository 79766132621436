import "./index.css";
import React, { memo } from "react";
import { Handle } from "reactflow";

import { ValidatorFn } from "../../../helpers/validationConnector";
import { FaFlagCheckered, FaPlus } from "react-icons/fa";
import { NodeHeader } from "../../Elements/NodeHeader";

export default memo(({ data }) => {
	return (
		<>
			<Handle
				type="source"
				position="right"
				className="handle"
				isConnectable={true}
				isValidConnection={ValidatorFn()}
			>
				<FaPlus size={8} style={{ pointerEvents: "none" }} />
			</Handle>

			<NodeHeader
				data={null}
				icon={<FaFlagCheckered />}
				text="Start point"
				start={true}
			/>

			<div className="sendMessageContent">
				<div>Onde seu bot inicia</div>
			</div>
		</>
	);
});
