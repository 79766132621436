import React from "react";
import { FaTrash } from "react-icons/fa";

import "./index.css";

const deleteNode = (props) => {
	const deleteById = () => {
		props.deleteNode(props.nodeId);
	};
	return (
		<div className="deleteNode" onClick={deleteById}>
			<FaTrash style={{ pointerEvents: "none" }} />
		</div>
	);
};

export default deleteNode;
