import "react-toastify/dist/ReactToastify.min.css";
import "./App.css";
import Flow from "./components/Flow/Flow";
import { AccountProvider } from "./context/Account";
import NotFound from "./pages/notFound";

import { BrowserRouter, Routes, Route } from "react-router-dom";

function App() {
	return (
		<AccountProvider>
			<BrowserRouter>
				<Routes>
					<Route path="/builder/:botId" element={<Flow />}></Route>
					<Route path="*" element={<NotFound />}></Route>
				</Routes>
			</BrowserRouter>
		</AccountProvider>
	);
}

export default App;
