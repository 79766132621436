import {
	FaClock,
	FaCode,
	FaCodeBranch,
	FaDollarSign,
	FaPen,
	FaQuestion,
	FaRegComment,
	FaSitemap,
	FaTasks,
	FaTerminal,
	FaUserNinja,
} from "react-icons/fa";
import { MdOpenInNew } from "react-icons/md";

function handleHeaderByType(type) {
	const types = {
		message: {
			icon: <FaRegComment size={22} color="#454962" />,
			title: "Mensagens",
		},
		gush: {
			icon: <FaUserNinja size={22} color="#454962" />,
			title: "Transferencia",
		},
		createtask: {
			icon: <FaTasks size={22} color="#454962" />,
			title: "Criar tarefa na Eloz",
		},
		createdeal: {
			icon: <FaDollarSign size={22} color="#454962" />,
			title: "Criar de negócio na Eloz",
		},
		sleep: {
			icon: <FaClock size={22} color="#454962" />,
			title: "Atraso",
		},
		setvar: {
			icon: <FaPen size={22} color="#454962" />,
			title: "Definir valor de uma variável",
		},
		webhook: {
			icon: <FaTerminal size={22} color="#454962" />,
			title: "Enviar Webhook",
		},
		question: {
			icon: <FaQuestion size={22} color="#454962" />,
			title: "Pergunta",
		},
		conditional: {
			icon: <FaCodeBranch size={22} color="#454962" />,
			title: "Condicional",
		},
		jump: {
			icon: <FaSitemap size={22} color="#454962" />,
			title: "Keyword Jump",
		},
		newVar: {
			icon: <FaCode size={22} color="#454962" />,
			title: "Cadastrar nova variável",
		},
	};

	const currentType = types[type];

	if (currentType) {
		return (
			<>
				{currentType.icon}
				<div>
					<span>{currentType.title}</span>
					{currentType.link && (
						<div className="modalHeader-link">
							<a href={currentType.link.url}>
								{currentType.link.title}
								<MdOpenInNew />
							</a>
						</div>
					)}
				</div>
			</>
		);
	}
	return "";
}

export { handleHeaderByType };
