import "./index.css";
import React, { memo } from "react";
import { FaClock, FaPlus } from "react-icons/fa";
import { Handle } from "reactflow";
import { ValidatorFn } from "../../../helpers/validationConnector";
import { NodeHeader } from "../../Elements/NodeHeader";

export default memo(({ data, ...rest }) => {
	return (
		<>
			<label className="nodeRef">Ref: {data.ref}</label>

			<Handle type="target" position="left" isConnectable={true} />

			<NodeHeader rest={rest} data={data} icon={<FaClock />} text="Delay" />

			<div className="sendMessageContent">
				<div>{data.sleepSeconds ? data.sleepSeconds : 0} segundos</div>
			</div>

			<Handle
				type="source"
				position="right"
				className="handle"
				isConnectable={true}
				isValidConnection={ValidatorFn()}
			>
				<FaPlus size={8} style={{ pointerEvents: "none" }} />
			</Handle>
		</>
	);
});
