import React from "react";

import { FaClone } from "react-icons/fa";

import "./index.css";
import { generateId } from "../../../../services/generateId";

const CloneNode = ({ data, setNodes, rest }) => {
	const handleCloneAction = () => {
		let newNode;
		const nodeId = generateId();
		newNode = {
			id: nodeId,
			type: data.type,
			position: {
				x: rest.xPos - 100,
				y: rest.yPos - 100,
			},
			sourcePosition: "right",
			targetPosition: "left",
			data: {
				...data,
				botId: data.botId,
				ref: nodeId,
				type: data.type,
				deleteNodeById: data.deleteNodeById,
				setNodes,
			},
		};

		setNodes((nds) => nds.concat(newNode));
	};

	return (
		<div className="editNode" onClick={handleCloneAction} title="Clonar ação">
			<FaClone />
		</div>
	);
};

export default CloneNode;
