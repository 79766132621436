import "./index.css";
import React, { memo } from "react";
import { FaPlus, FaQuestion } from "react-icons/fa";
import { Handle } from "reactflow";
import { ValidatorFn } from "../../../helpers/validationConnector";

import { NodeHeader } from "../../Elements/NodeHeader";

export default memo(({ data, ...rest }) => {
	const btnList = [];

	if (data.questionList?.sections) {
		Object.keys(data.questionList.sections).map((sec) => {
			data.questionList.sections[sec].items.forEach((it) =>
				btnList.push({
					id: it.id,
					title: `${data.questionList.sections[sec].sectionTitle} - ${it.title}`,
				})
			);
		});
	}

	return (
		<>
			<label className="nodeRef">Ref: {data.ref}</label>
			<Handle type="target" position="left" isConnectable={true} />
			<NodeHeader
				data={data}
				rest={rest}
				icon={<FaQuestion />}
				text="Perguntar"
			/>

			<div className="sendMessageContent">
				<div>{data.question}</div>
			</div>

			{data.questionTypeResponse == "buttons" && (
				<div className="questionButtons">
					{data.questionButtons.map((x, i) => (
						<Item
							title={x.textButton}
							i={i}
							id={`btn-${x.id}`}
							isLast={i === data.questionButtons.length - 1}
						/>
					))}
				</div>
			)}

			{data.questionTypeResponse == "list" && (
				<div className="questionButtons">
					{btnList.map((x, i) => (
						<Item
							x={x}
							i={i}
							title={x.title}
							id={x.id}
							isLast={i === btnList.length - 1}
						/>
					))}
				</div>
			)}

			{(data.questionTypeResponse == "simple" ||
				!data.questionTypeResponse) && (
				<Handle
					type="source"
					position="right"
					className="handle"
					isConnectable={true}
					isValidConnection={ValidatorFn()}
				>
					<FaPlus size={8} style={{ pointerEvents: "none" }} />
				</Handle>
			)}
		</>
	);
});

function Item({ i, title, id, isLast }) {
	return (
		<div className={isLast ? "item-last" : ""}>
			<div className="conditionalContent">{title}</div>
			<Handle
				type="source"
				position="right"
				id={id}
				className="handle"
				isConnectable={true}
				isValidConnection={ValidatorFn()}
			>
				<FaPlus size={8} style={{ pointerEvents: "none" }} />
			</Handle>
		</div>
	);
}
