import React, { useContext } from "react";
import {
	FaQuestion,
	FaRegComment,
	FaCodeBranch,
	FaSitemap,
	FaPen,
	FaTerminal,
	FaUserNinja,
	FaToggleOn,
	FaUserAlt,
	FaClock,
	FaTrash,
	FaTasks,
	FaDollarSign,
	FaPlus,
} from "react-icons/fa";
import { toast } from "react-toastify";

import { serverApi } from "../../services/api";

import { AccountContext } from "../../context/Account";
import { defaultVariables } from "../../helpers/default-variables";

const Sidebar = (data) => {
	const { account } = useContext(AccountContext);

	const onDragStart = (event, nodeType) => {
		event.dataTransfer.setData("application/reactflow", nodeType);
		event.dataTransfer.effectAllowed = "move";
	};

	const handleDeleteVariable = async (variable) => {
		const name = variable.name;

		const isVarUsed = data.nodes.some((node) => {
			if (node?.data?.questionVar === name) return true;
			if (
				node?.data?.webhookSaveVars?.length &&
				node?.data.webhookSaveVars.find((a) => a.varBot === name)
			)
				return true;
			if (node?.data.question?.includes(`{{${name}}}`)) return true;
			if (node?.data?.msgMessage?.includes(`{{${name}}}`)) return true;
			if (node?.data.jumpVar === name) return true;
			if (node?.data?.condValue?.includes(`{{${name}}}`)) return true;
			if (node?.data?.webhookUrl?.includes(`{{${name}}}`)) return true;
			if (node?.data?.webhookBody?.includes(`{{${name}}}`)) return true;
			return false;
		});

		if (isVarUsed) {
			toast.warn("Variável sendo utilizada.");
			return;
		}

		const willDelete = window.confirm(
			"Deseja realmente excluir esta variável?"
		);
		if (!willDelete) return;
		try {
			await serverApi.post(`/bot/variable/delete/${data.botId}`, {
				name,
			});
			data.getVars();
		} catch (error) {
			alert(error.response.data.message);
		}
	};

	return (
		<>
			<aside>
				<div className="description">
					Para adicionar um elemento, arraste-o para o quadro.
				</div>
				<div className="actions">
					<div
						className="dndnode nodeSidebar"
						onDragStart={(event) => onDragStart(event, "message")}
						draggable
					>
						<FaRegComment></FaRegComment>&nbsp;&nbsp;Enviar mensagem
					</div>
					<div
						className="dndnode nodeSidebar"
						onDragStart={(event) => onDragStart(event, "question")}
						draggable
					>
						<FaQuestion></FaQuestion>&nbsp;&nbsp;Perguntar
					</div>

					<div
						className="dndnode nodeSidebar"
						onDragStart={(event) => onDragStart(event, "sleep")}
						draggable
					>
						<FaClock></FaClock>&nbsp;&nbsp;Delay
					</div>

					<div
						className="dndnode nodeSidebar"
						onDragStart={(event) => onDragStart(event, "conditional")}
						draggable
					>
						<FaCodeBranch></FaCodeBranch>&nbsp;&nbsp;Condicional
					</div>
					<div
						className="dndnode nodeSidebar"
						onDragStart={(event) => onDragStart(event, "jump")}
						draggable
					>
						<FaSitemap></FaSitemap>&nbsp;&nbsp;Keyword Jump
					</div>
					<div
						className="dndnode nodeSidebar"
						onDragStart={(event) => onDragStart(event, "setvar")}
						draggable
					>
						<FaPen></FaPen>&nbsp;&nbsp;Setar valor de variável
					</div>
					<div
						className="dndnode nodeSidebar"
						onDragStart={(event) => onDragStart(event, "webhook")}
						draggable
					>
						<FaTerminal></FaTerminal>&nbsp;&nbsp;Webhook
					</div>

					{account.elozAccount && (
						<div
							className="dndnode nodeSidebar"
							onDragStart={(event) => onDragStart(event, "createupdatecontact")}
							draggable
						>
							<FaUserAlt></FaUserAlt>&nbsp;&nbsp;Criar/Atualizar contato
						</div>
					)}

					<div
						className="dndnode nodeSidebar"
						onDragStart={(event) => onDragStart(event, "end")}
						draggable
					>
						<FaToggleOn></FaToggleOn>&nbsp;&nbsp;Encerrar conversa
					</div>
					<div
						className="dndnode nodeSidebar"
						onDragStart={(event) => onDragStart(event, "gush")}
						draggable
					>
						<FaUserNinja></FaUserNinja>&nbsp;&nbsp;Transferir para
						atendente/operador
					</div>

					{account.elozAccount && (
						<>
							<div
								className="dndnode nodeSidebar"
								onDragStart={(event) => onDragStart(event, "createtask")}
								draggable
							>
								<FaTasks />
								&nbsp;&nbsp;Criar tarefa
							</div>
							<div
								className="dndnode nodeSidebar"
								onDragStart={(event) => onDragStart(event, "createdeal")}
								draggable
							>
								<FaDollarSign />
								&nbsp;&nbsp;
								{account.isGov ? "Criar solicitação" : "Criar negócio"}
							</div>
						</>
					)}
				</div>

				<div className="variables">
					<div className="var-header">
						<h4>Variáveis</h4>
						<div className="btnCreateVar" onClick={data.handleModalVariable}>
							<FaPlus />
						</div>
					</div>
					<div className="variablesSet">
						{data.variables &&
							data.variables.map((it) => (
								<div className="variable-item">
									<div
										className={`item ${
											defaultVariables.includes(it.name) ? "defaultVar" : ""
										}`}
									>
										{it.name}
									</div>
									<div
										className="variable-delete-item"
										onClick={() => {
											handleDeleteVariable(it);
										}}
									>
										<FaTrash />
									</div>
								</div>
							))}
					</div>
				</div>
			</aside>
		</>
	);
};

export default Sidebar;
