import React, { useEffect } from "react";
import AddVariablesInText from "../AddVariablesInText";
import { handleContentToPut } from "../../../helpers/replace-variables-html";

function CreateTask({ setObjectData, objectData, owners, variables }) {
	const handleChange = (e) => {
		setObjectData({ ...objectData, [e.target.name]: e.target.value });
	};

	useEffect(() => {
		let inputs = [
			...document.querySelectorAll(".form_create_task div.inputText"),
		];
		if (objectData) {
			Object.entries(objectData).forEach(([k, v]) => {
				const found = inputs.find((el) => el.id == k);
				if (k === "source" && !v) {
					v = "Chatbot";
				}
				if (found && v && found.nodeName === "DIV") {
					found.innerHTML = handleContentToPut(v, variables);
				}
			});
		}

		if (!Object.keys(objectData).length) {
			inputs.forEach((inpt) => {
				if (inpt.nodeName === "DIV") {
					inpt.innerHTML = "";
				}
			});
		}
	}, [objectData]);

	return (
		<div className="form_create_task">
			<h3>Criar tarefa:</h3>
			<div className="form-create">
				<div className="input-group">
					<label htmlFor="" className="required">
						Nome
					</label>
					<div
						value={objectData?.name || ""}
						id="name"
						className="inputText"
						contentEditable="true"
					></div>
				</div>
				<div className="input-group">
					<label htmlFor="">Descrição</label>
					<div
						value={objectData?.description || ""}
						id="description"
						className="inputText"
						contentEditable="true"
					></div>
				</div>
				<div className="input-group">
					<label htmlFor="" className="required">
						Vence em (dias)
					</label>
					<div
						value={objectData?.due_date || ""}
						id="due_date"
						className="inputText"
						contentEditable="true"
					></div>
				</div>
				<div className="input-group">
					<label htmlFor="" className="required">
						Hora de vencimento
					</label>
					<input
						value={objectData?.due_time || ""}
						type="time"
						name="due_time"
						className="inputText"
						onChange={handleChange}
						id="due_time"
					/>
				</div>
				<div className="input-group">
					<label htmlFor="" className="required">
						Tipo
					</label>
					<select
						name="task_type"
						id="task_type"
						value={objectData?.task_type || ""}
						onChange={handleChange}
					>
						<option value=""></option>
						<option value="todo">To do</option>
						<option value="email">Email</option>
						<option value="call">Ligação</option>
						<option value="whatsapp">Whatsapp</option>
					</select>
				</div>
				<div className="input-group">
					<label htmlFor="" className="required">
						Prioridade
					</label>
					<select
						name="priority"
						id="priority"
						value={objectData?.priority || ""}
						onChange={handleChange}
					>
						<option value=""></option>
						<option value="0">Baixa</option>
						<option value="1">Média</option>
						<option value="2">Alta</option>
					</select>
				</div>
				<div className="input-group">
					<label htmlFor="">Origem</label>
					<div
						value={objectData?.source || ""}
						id="source"
						className="inputText"
						contentEditable="true"
					></div>
				</div>
				<div className="input-group">
					<label htmlFor="" className="required">
						Proprietario
					</label>
					<select
						name="owner"
						id="owner"
						value={objectData?.owner || ""}
						onChange={handleChange}
					>
						<option value=""></option>
						{owners.map((owner) => (
							<option value={`${owner.id}`} key={owner.id}>
								{owner.name} ({owner.email})
							</option>
						))}
					</select>
				</div>
				<AddVariablesInText variables={variables}></AddVariablesInText>
			</div>
		</div>
	);
}

export { CreateTask };
