const nameConditions = {
	eq: "é igual a",
	ne: "é diferente de",
	gt: "é maior que",
	ge: "é maior ou igual a",
	lt: "é menor que",
	le: "é menor ou igual a",
	ik: "é conhecido",
	startsWith: "começa com",
	endsWith: "termina com",
	contains: "contém",
	cpf: "CPF válido",
	cnpj: "CNPJ válido",
	num: "É numero",
	email: "E-mail é válido",
	cep: "CEP é válido",
	date: "É data",
};

module.exports = nameConditions;
