const checkVarExists = (v, vars) => {
	v = v.replace("{{", "");
	v = v.replace("}}", "");
	if (vars.find((i) => i.name == v)) {
		return true;
	}
	return false;
};

const handleContentToPut = (content, vars) => {
	content = content.replaceAll("\r\n", "<br>");
	content = content.replaceAll("\n", "<br>");

	let regexVars = new RegExp(`{{([^}]*)}}`, "gm");
	let result;
	let c2 = content;
	while ((result = regexVars.exec(c2))) {
		const varToReplace = result[0];
		if (checkVarExists(varToReplace, vars)) {
			content = content.replaceAll(
				varToReplace,
				`<span contenteditable="false" class="variableInText">${varToReplace}</span>`
			);
		}
	}

	return content;
};

export { handleContentToPut };
