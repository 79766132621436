import React, { memo } from "react";
import { FaDollarSign, FaPlus } from "react-icons/fa";
import { Handle } from "reactflow";

import "./index.css";
import { ValidatorFn } from "../../../helpers/validationConnector";
import { NodeHeader } from "../../Elements/NodeHeader";

export default memo(({ data, ...rest }) => {
	let name = data?.objectData?.name;

	return (
		<>
			<label className="nodeRef">Ref: {data.ref}</label>
			<Handle type="target" position="left" isConnectable={true} />

			<NodeHeader
				rest={rest}
				data={data}
				icon={<FaDollarSign />}
				text="Criar negócio"
			/>

			{name && (
				<div className="sendMessageContent">
					<div>{name}</div>
				</div>
			)}

			<Handle
				type="source"
				position="right"
				className="handle"
				isConnectable={true}
				isValidConnection={ValidatorFn()}
			>
				<FaPlus size={8} style={{ pointerEvents: "none" }} />
			</Handle>
		</>
	);
});
