const requiredFields = [
	"owner",
	"name",
	"due_date",
	"due_time",
	"task_type",
	"priority",
];

function validateTaskData() {
	let divs = [...document.querySelectorAll(".form_create_task div.inputText")];
	let inputs = [
		...document.querySelectorAll(".form_create_task select"),
		...document.querySelectorAll(".form_create_task input"),
	];
	let fields = {};

	divs.forEach((input) => {
		fields[input.id] = input.textContent;
	});
	inputs.forEach((input) => {
		fields[input.id] = input.value;
	});

	if (requiredFields.some((field) => !fields[field])) {
		return false;
	}

	return fields;
}

export { validateTaskData };
